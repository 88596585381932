.title {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 140px;
    height: 1.2em;
    white-space: nowrap;
    font-size: 13px;
}

.price {
    font-size: 13px;
}

.card {
    border: 2px solid greenyellow !important;
}

.new_images img {
    width: 30%;
    height: 120px;
    margin: 6px;
    border-radius: 20px;
}

.new_images img:first-child {
    border: 1px solid #760166;
    padding: 38px 34px;
}

.no_data {
    color: #760166;
    text-align: center;
    margin-top: 30px;
}

.loader {
    position: absolute;
    /* Set position to absolute */
    top: 50%;
    /* Move the element down by 50% of its parent's height */
    transform: translateY(-50%);
    /* Move the element up by 50% of its own height */
    height: 100vh;
    /* Set the height to 100% of viewport height */
    left: 0;
    /* Align to the left */
    right: 0;
    /* Align to the right */
    margin: auto;
    /* Center horizontally */
    justify-content: center;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background-color: rgba(54, 54, 54, .2) !important;
}

.loader svg {
    position: absolute;
    top: 50%;
}