.nav_tabs {
    border-bottom: 0px !important;
}

.nav_item {
    width: 50%;
}

.nav_link {
    width: 100%;
    border: 0px !important;
    color: #000 !important;
}

.trending_people, .trending_merch {
    display: inline-block;
    width: 32%;
    color: #000 !important;
    text-decoration: none;
    margin: 50px 0px 0px 0px;
    text-align: center;
    border: 2px solid #760166;
    padding: 20px 0px;
    border-radius: 10px;
}

.trending_people {
    margin-right: 2.5px;
}

.trending_merch {
    margin-left: 2.5px;
    margin-right: 0px;
}

.trending_people_section, .trending_location_section {
    background-color: lightgray;
    margin-top: 20px;
    padding: 5px;
}

.filter {
    float: right;
    color: #760166;
    margin-top: 4px;
}


.find_cool_stuff{
    text-align: center;
    border-radius: 20px;
    border: 1px solid lightgray;
    padding: 20px 0px;
    margin-top: 20px;
}