body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home_page_tabs .active {
  border: 0px !important;
  border-bottom: 2px solid #760166 !important;
}

.hero_slider .slick-dots li.slick-active button:before {
  color: #760166;
}

.hero_slider .slick-dots li {
  width: 10px;
}

.modal-open {
  padding-right: 0px !important;
}

.rating_modal_wrapper .modal-dialog, .merchandise_popup .modal-dialog {
  margin: 0px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.logout_modal .modal-dialog {
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 100%;
  width: 95%;
}

.bounding-box {
  position: absolute;
  border: 2px solid transparent;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 99999;
}

.center-point{
  position: absolute;
  width: 30px;
  cursor: pointer;
}

.delete_modal .modal-dialog{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0%) !important;
  width: 100%;
}