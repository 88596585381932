.bottom_navbar {
    overflow: hidden;
    width: 50%;
    display: table;
    margin: 5% auto 0;
    padding: 20px 0px !important;
    background-color: aliceblue;
}


.main_icons {
    text-align: center;
}

.main_icons img {
    margin: 0px 15px;
    cursor: pointer;
}

.plus_img {
    position: absolute;
    right: 10px;
    top: 20%;
}

.preview_image {
    height: 100vh;
    background-color: #000;
    position: relative;
}

.preview_image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
}

.retake_photo{
    position: absolute;
    bottom: 5%;
    left: 10px;
    background-color: transparent !important;
    color: #fff !important;
    border: 0px !important;
    font-weight: 700 !important;
}

.use_current_photo{
    position: absolute;
    bottom: 5%;
    right: 10px;
    background-color: transparent !important;
    color: #fff !important;
    border: 0px !important;
    font-weight: 700 !important;
}

.gallery_upload{
    display: inline;
    position: relative;
}

.upload_input{
    position: absolute;
    width: 100%;
    left: 0;
    opacity: 0;
    top: 0;
    height: 100%;
}

.icon_div{
    justify-content: space-around;
}

.icon_div div{
    border: 1px solid;
    padding: 25px 40px 10px 40px;
    border-radius: 15px;
    text-align: center;
}

.icon_div div p{
    margin-top: 10px;
}


.loader {
    position: absolute; /* Set position to absolute */
    top: 50%; /* Move the element down by 50% of its parent's height */
    transform: translateY(-50%); /* Move the element up by 50% of its own height */
    height: 100vh; /* Set the height to 100% of viewport height */
    left: 0; /* Align to the left */
    right: 0; /* Align to the right */
    margin: auto; /* Center horizontally */
    justify-content: center;
    -webkit-backdrop-filter: blur(10px)!important;
    backdrop-filter: blur(10px)!important;
    background-color: rgba(54,54,54,.2)!important;
}

.loader svg{
    position: absolute;
    top: 50%;
}